let getDatasetColumns = (dataset) => {
	return Object.keys(dataset.schema.properties).map(key => {
		return {
			name: key,
			...dataset.schema.properties[key]
		}
	})
}

let getDatasetColumnByName = (dataset, columnName) => {
	return dataset.schema.properties[columnName]
}

let getFirstDatasetColumn = (dataset) => {
	return dataset.schema.properties[Object.keys(dataset.schema.properties)[0]]
}

let getFirstDatasetColumnName = (dataset) => {
	return Object.keys(dataset.schema.properties)[0]
}

export {
	getDatasetColumns,
	getDatasetColumnByName,
	getFirstDatasetColumn,
	getFirstDatasetColumnName
}